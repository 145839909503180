/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
type Documents = {
    "\n  fragment GiftCardFragment on GiftCardGetGiftCardResponse {\n    number\n    status\n    validTo\n    externalDocumentNo\n    amount\n  }\n": typeof types.GiftCardFragmentFragmentDoc,
    "\n  mutation findGiftcardQuery(\n    $input: BookingFlowFindGiftCardRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFind(input: $input) {\n          ...GiftCardFragment\n        }\n      }\n    }\n  }\n": typeof types.FindGiftcardQueryDocument,
    "\n  mutation FindGiftCardExternalDocument(\n    $input: BookingFlowFindGiftCardRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFind(input: $input) {\n          externalDocumentNo\n        }\n      }\n    }\n  }\n": typeof types.FindGiftCardExternalDocumentDocument,
    "\n  mutation BLGetGiftCard($input: BookingFlowFindGiftCardRequestModelInput!) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFind(input: $input) {\n          amount\n          number\n          externalDocumentNo\n          customerName\n          customerEmail\n          message\n          shippingMethod\n          status\n          recipientName\n          recipientAddress\n          recipientPostCode\n        }\n      }\n    }\n  }\n": typeof types.BlGetGiftCardDocument,
    "\n  query BLGetTransactionStatus($search: String!) {\n    Bluelagoon {\n      Booking {\n        paymentTransactionStatusBySearch(search: $search) {\n          ... on PaymentPlanetGetPaymentStatusResponse {\n            data {\n              refNo\n              data {\n                detail {\n                  fail {\n                    message\n                  }\n                }\n                status\n                refNo\n                transactionId\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": typeof types.BlGetTransactionStatusDocument,
    "\n  mutation BLCreateGiftCard($input: BookingFlowGiftCardOrderModelInput!) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardCreate(input: $input) {\n          giftCardNo\n        }\n      }\n    }\n  }\n": typeof types.BlCreateGiftCardDocument,
    "\n  mutation BLFinalizeGiftCard($info: BookingFlowFinalizeGiftCardModelInput!) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFinalize(input: $info) {\n          giftCard {\n            externalDocNo\n          }\n        }\n      }\n    }\n  }\n": typeof types.BlFinalizeGiftCardDocument,
    "\n  fragment BLSpaTimeSlotAvailability on BookingFlowCartGetProductAvailabilityResponse {\n    offer {\n      offerId\n      productId\n      time\n      available\n      price\n    }\n  }\n": typeof types.BlSpaTimeSlotAvailabilityFragmentDoc,
    "\n  mutation BLCreateNewSpaCart {\n    Bluelagoon {\n      Booking {\n        postCartDayspaNew {\n          id\n        }\n      }\n    }\n  }\n": typeof types.BlCreateNewSpaCartDocument,
    "\n  mutation BLAddToCart($input: BookingFlowCartAddItemRequestModelInput!) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaAdd(input: $input) {\n          id\n        }\n      }\n    }\n  }\n": typeof types.BlAddToCartDocument,
    "\n  mutation BLRemoveItemFromCart($cartId: UUID!, $itemId: String!) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaRemove(input: { cartId: $cartId, itemId: $itemId }) {\n          id\n        }\n      }\n    }\n  }\n": typeof types.BlRemoveItemFromCartDocument,
    "\n  fragment BLSpaExtraAvailability on BookingFlowCartGetProductAvailabilityResponse {\n    offer {\n      offerId\n      time\n      available\n      productId\n      price\n    }\n  }\n": typeof types.BlSpaExtraAvailabilityFragmentDoc,
    "\n  query BLProductAvailability(\n    $input: BookingFlowGetProductAvailabilityRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaProductAvailability(input: $input) {\n          __typename\n          ...BLSpaCalendarAvailability\n          ...BLSpaTimeSlotAvailability\n          ...BLSpaExtraAvailability\n        }\n      }\n    }\n  }\n": typeof types.BlProductAvailabilityDocument,
    "\n  query BLSpaProductCatalog {\n    Bluelagoon { \n      Booking {\n        cartDayspaProducts {\n          products { \n            productId\n            price\n            discountedPrice\n            fixedDiscountPercentage\n            timeSlotLength\n          }\n        }\n      }\n    }\n  }\n": typeof types.BlSpaProductCatalogDocument,
    "\n  query BLGetSpaCart($cartId: UUID!) {\n    Bluelagoon {\n      Booking {\n        cartDayspaById(id: $cartId) {\n          id\n          paymentAmount\n          paymentRequired\n          promoCode\n          giftCardAmount\n          discountedAmount\n          giftCards {\n            no\n            balance\n            chargeAmount\n          }\n          customer {\n            firstName\n            lastName\n            email\n            phone\n            nationality\n            newsletter\n          }\n          membership {\n            type\n          }\n          items {\n            id\n            productId\n            qty\n            fullName\n            price\n            totalLinePrice # price * qty\n            linePrice # discountedPrice * qty\n            discountedPrice\n            type\n            meta\n          }\n        }\n      }\n    }\n  }\n": typeof types.BlGetSpaCartDocument,
    "\n  mutation BLLoginWithBookingRef($email: String!, $bookingRef: String!) {\n    Bluelagoon {\n      Booking {\n        postMybookingLogin(input: { email: $email, bookingRef: $bookingRef }) {\n          token\n          refreshToken\n          _error {\n            errorCode\n            errorMessage\n          }\n        }\n      }\n    }\n  }\n": typeof types.BlLoginWithBookingRefDocument,
    "\n  query BLSpaBooking {\n    Bluelagoon {\n      Booking {\n        mybookingBooking {\n          bookingId\n          arrivalTime\n          adultCount\n          teenagerCount\n          childrenCount\n          packageType\n          products {\n            productId\n            qty\n          }\n          customer {\n            email\n          }\n        }\n      }\n    }\n  }\n": typeof types.BlSpaBookingDocument,
    "\n  query BLSpaBookingActivities {\n    Bluelagoon {\n      Booking {\n        mybookingActivities {\n          activityLines {\n            numberOfPersons\n            numberOfAdults\n            numberOfChildren\n            quantity\n            productNo\n            reservationDate\n            comment\n            data\n          }\n        }\n      }\n    }\n  }\n": typeof types.BlSpaBookingActivitiesDocument,
    "\n  mutation CheckoutTransaction($input: BookingFlowCartConfirmModelInput!) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaCheckout(input: $input) {\n          bookingNumber\n        }\n      }\n    }\n  }\n": typeof types.CheckoutTransactionDocument,
    "\n  fragment BLSpaCalendarAvailability on BookingFlowCartGetProductAvailabilityResponse {\n    offer {\n      productId\n      time\n      available\n    }\n  }\n": typeof types.BlSpaCalendarAvailabilityFragmentDoc,
    "\n  mutation BLCustomerInfo($input: BookingFlowCartStoreCustomerModelInput!) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaCustomer(input: $input) {\n          __typename\n          cart {\n            id\n          }\n        }\n      }\n    }\n  }\n": typeof types.BlCustomerInfoDocument,
    "\n  mutation BLAddGiftCard($input: BookingFlowCartAddGiftCardRequestModelInput!) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaAddGiftCard(input: $input) {\n          id\n          giftCards {\n            no\n            balance\n            chargeAmount\n          }\n        }\n      }\n    }\n  }\n": typeof types.BlAddGiftCardDocument,
    "\n  mutation BLRemoveGiftCard(\n    $input: BookingFlowCartRemoveGiftCardRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaRemoveGiftCard(input: $input) {\n          id\n          giftCards {\n            no\n            balance\n            chargeAmount\n          }\n        }\n      }\n    }\n  }\n": typeof types.BlRemoveGiftCardDocument,
    "\n  mutation HBAddPromoCode(\n    $input: BookingFlowCartAddPromoCodeRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaAddPromoCode(input: $input) {\n          id\n          promoCode\n        }\n      }\n    }\n  }\n": typeof types.HbAddPromoCodeDocument,
    "\n  mutation StartTransaction($input: BookingFlowCartTransactionModelInput) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaTransaction(input: $input) {\n          paymentId\n          transactionId\n          _error {\n            errorCode\n            errorMessage\n          }\n        }\n      }\n    }\n  }\n": typeof types.StartTransactionDocument,
    "\n  fragment BLSpaTransportationOffer on BookingFlowCartGetProductAvailabilityResponse {\n    offer {\n      offerId\n      productId\n      time\n      available\n      price\n      componentType\n      componentId\n      subOffers {\n        additionalInfo\n      }\n    }\n  }\n": typeof types.BlSpaTransportationOfferFragmentDoc,
    "\n  query BLSpaTransportationAvailability(\n    $input: BookingFlowGetProductAvailabilityRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaProductAvailability(input: $input) {\n          ...BLSpaTransportationOffer\n        }\n      }\n    }\n  }\n": typeof types.BlSpaTransportationAvailabilityDocument,
    "\n  query BLTransferLocations {\n    Bluelagoon {\n      Booking {\n        cartDayspaTransferLocations {\n          locations {\n            locationId\n            componentId\n            type\n            name\n            address\n            city\n            latitude\n            longitude\n          }\n        }\n      }\n    }\n  }\n": typeof types.BlTransferLocationsDocument,
    "\n  mutation InitMembershipTransaction(\n    $input: BookingFlowOrderMembershipTransactionModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postMembershipTransactionInit(input: $input) {\n          transactionId\n          membershipId\n          paymentTransactionId\n        }\n      }\n    }\n  }\n": typeof types.InitMembershipTransactionDocument,
    "\n  mutation FinalizeMembershipTransaction(\n    $input: BookingFlowOrderMembershipFinalizeTransactionModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postMembershipTransactionFinalize(input: $input) {\n          authorizationCode\n          transactionId\n          cardHolder {\n            id\n            firstName\n            lastName\n            personalId\n            email\n            phoneNumber\n          }\n        }\n      }\n    }\n  }\n": typeof types.FinalizeMembershipTransactionDocument,
    "\n  query InRoomProducts{\n    Bluelagoon {\n      Booking {\n        cartHotelProducts {\n          products {\n            productId\n            price\n            displayName\n          }\n        }\n      }\n    }\n  }\n": typeof types.InRoomProductsDocument,
    "\n  fragment ConversionRates on PaymentPlanetCurrencyConversionRate {\n    currency\n    value\n  }\n": typeof types.ConversionRatesFragmentDoc,
    "\n  fragment ErrorFragment on MicrosoftAspNetCoreMvcProblemDetails {\n    title\n    status\n    detail\n    type\n    instance\n  }\n": typeof types.ErrorFragmentFragmentDoc,
    "\n  query getCurrencyRates {\n    Bluelagoon {\n      Booking {\n        paymentCurrencyRates {\n          ... on PaymentPlanetCollectCurrencyConversionRatesResponse {\n            conversionRates {\n              rates {\n                ...ConversionRates\n              }\n            }\n          }\n          ...ErrorFragment\n        }\n        paymentMulticurrencyRates {\n          ... on PaymentPlanetCollectCurrencyConversionRatesResponse {\n            conversionRates {\n              reportDetail {\n                retrievalReferenceNumber\n              }\n              rates {\n                ...ConversionRates\n              }\n            }\n          }\n          ...ErrorFragment\n        }\n      }\n    }\n  }\n": typeof types.GetCurrencyRatesDocument,
};
const documents: Documents = {
    "\n  fragment GiftCardFragment on GiftCardGetGiftCardResponse {\n    number\n    status\n    validTo\n    externalDocumentNo\n    amount\n  }\n": types.GiftCardFragmentFragmentDoc,
    "\n  mutation findGiftcardQuery(\n    $input: BookingFlowFindGiftCardRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFind(input: $input) {\n          ...GiftCardFragment\n        }\n      }\n    }\n  }\n": types.FindGiftcardQueryDocument,
    "\n  mutation FindGiftCardExternalDocument(\n    $input: BookingFlowFindGiftCardRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFind(input: $input) {\n          externalDocumentNo\n        }\n      }\n    }\n  }\n": types.FindGiftCardExternalDocumentDocument,
    "\n  mutation BLGetGiftCard($input: BookingFlowFindGiftCardRequestModelInput!) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFind(input: $input) {\n          amount\n          number\n          externalDocumentNo\n          customerName\n          customerEmail\n          message\n          shippingMethod\n          status\n          recipientName\n          recipientAddress\n          recipientPostCode\n        }\n      }\n    }\n  }\n": types.BlGetGiftCardDocument,
    "\n  query BLGetTransactionStatus($search: String!) {\n    Bluelagoon {\n      Booking {\n        paymentTransactionStatusBySearch(search: $search) {\n          ... on PaymentPlanetGetPaymentStatusResponse {\n            data {\n              refNo\n              data {\n                detail {\n                  fail {\n                    message\n                  }\n                }\n                status\n                refNo\n                transactionId\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.BlGetTransactionStatusDocument,
    "\n  mutation BLCreateGiftCard($input: BookingFlowGiftCardOrderModelInput!) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardCreate(input: $input) {\n          giftCardNo\n        }\n      }\n    }\n  }\n": types.BlCreateGiftCardDocument,
    "\n  mutation BLFinalizeGiftCard($info: BookingFlowFinalizeGiftCardModelInput!) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFinalize(input: $info) {\n          giftCard {\n            externalDocNo\n          }\n        }\n      }\n    }\n  }\n": types.BlFinalizeGiftCardDocument,
    "\n  fragment BLSpaTimeSlotAvailability on BookingFlowCartGetProductAvailabilityResponse {\n    offer {\n      offerId\n      productId\n      time\n      available\n      price\n    }\n  }\n": types.BlSpaTimeSlotAvailabilityFragmentDoc,
    "\n  mutation BLCreateNewSpaCart {\n    Bluelagoon {\n      Booking {\n        postCartDayspaNew {\n          id\n        }\n      }\n    }\n  }\n": types.BlCreateNewSpaCartDocument,
    "\n  mutation BLAddToCart($input: BookingFlowCartAddItemRequestModelInput!) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaAdd(input: $input) {\n          id\n        }\n      }\n    }\n  }\n": types.BlAddToCartDocument,
    "\n  mutation BLRemoveItemFromCart($cartId: UUID!, $itemId: String!) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaRemove(input: { cartId: $cartId, itemId: $itemId }) {\n          id\n        }\n      }\n    }\n  }\n": types.BlRemoveItemFromCartDocument,
    "\n  fragment BLSpaExtraAvailability on BookingFlowCartGetProductAvailabilityResponse {\n    offer {\n      offerId\n      time\n      available\n      productId\n      price\n    }\n  }\n": types.BlSpaExtraAvailabilityFragmentDoc,
    "\n  query BLProductAvailability(\n    $input: BookingFlowGetProductAvailabilityRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaProductAvailability(input: $input) {\n          __typename\n          ...BLSpaCalendarAvailability\n          ...BLSpaTimeSlotAvailability\n          ...BLSpaExtraAvailability\n        }\n      }\n    }\n  }\n": types.BlProductAvailabilityDocument,
    "\n  query BLSpaProductCatalog {\n    Bluelagoon { \n      Booking {\n        cartDayspaProducts {\n          products { \n            productId\n            price\n            discountedPrice\n            fixedDiscountPercentage\n            timeSlotLength\n          }\n        }\n      }\n    }\n  }\n": types.BlSpaProductCatalogDocument,
    "\n  query BLGetSpaCart($cartId: UUID!) {\n    Bluelagoon {\n      Booking {\n        cartDayspaById(id: $cartId) {\n          id\n          paymentAmount\n          paymentRequired\n          promoCode\n          giftCardAmount\n          discountedAmount\n          giftCards {\n            no\n            balance\n            chargeAmount\n          }\n          customer {\n            firstName\n            lastName\n            email\n            phone\n            nationality\n            newsletter\n          }\n          membership {\n            type\n          }\n          items {\n            id\n            productId\n            qty\n            fullName\n            price\n            totalLinePrice # price * qty\n            linePrice # discountedPrice * qty\n            discountedPrice\n            type\n            meta\n          }\n        }\n      }\n    }\n  }\n": types.BlGetSpaCartDocument,
    "\n  mutation BLLoginWithBookingRef($email: String!, $bookingRef: String!) {\n    Bluelagoon {\n      Booking {\n        postMybookingLogin(input: { email: $email, bookingRef: $bookingRef }) {\n          token\n          refreshToken\n          _error {\n            errorCode\n            errorMessage\n          }\n        }\n      }\n    }\n  }\n": types.BlLoginWithBookingRefDocument,
    "\n  query BLSpaBooking {\n    Bluelagoon {\n      Booking {\n        mybookingBooking {\n          bookingId\n          arrivalTime\n          adultCount\n          teenagerCount\n          childrenCount\n          packageType\n          products {\n            productId\n            qty\n          }\n          customer {\n            email\n          }\n        }\n      }\n    }\n  }\n": types.BlSpaBookingDocument,
    "\n  query BLSpaBookingActivities {\n    Bluelagoon {\n      Booking {\n        mybookingActivities {\n          activityLines {\n            numberOfPersons\n            numberOfAdults\n            numberOfChildren\n            quantity\n            productNo\n            reservationDate\n            comment\n            data\n          }\n        }\n      }\n    }\n  }\n": types.BlSpaBookingActivitiesDocument,
    "\n  mutation CheckoutTransaction($input: BookingFlowCartConfirmModelInput!) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaCheckout(input: $input) {\n          bookingNumber\n        }\n      }\n    }\n  }\n": types.CheckoutTransactionDocument,
    "\n  fragment BLSpaCalendarAvailability on BookingFlowCartGetProductAvailabilityResponse {\n    offer {\n      productId\n      time\n      available\n    }\n  }\n": types.BlSpaCalendarAvailabilityFragmentDoc,
    "\n  mutation BLCustomerInfo($input: BookingFlowCartStoreCustomerModelInput!) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaCustomer(input: $input) {\n          __typename\n          cart {\n            id\n          }\n        }\n      }\n    }\n  }\n": types.BlCustomerInfoDocument,
    "\n  mutation BLAddGiftCard($input: BookingFlowCartAddGiftCardRequestModelInput!) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaAddGiftCard(input: $input) {\n          id\n          giftCards {\n            no\n            balance\n            chargeAmount\n          }\n        }\n      }\n    }\n  }\n": types.BlAddGiftCardDocument,
    "\n  mutation BLRemoveGiftCard(\n    $input: BookingFlowCartRemoveGiftCardRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaRemoveGiftCard(input: $input) {\n          id\n          giftCards {\n            no\n            balance\n            chargeAmount\n          }\n        }\n      }\n    }\n  }\n": types.BlRemoveGiftCardDocument,
    "\n  mutation HBAddPromoCode(\n    $input: BookingFlowCartAddPromoCodeRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaAddPromoCode(input: $input) {\n          id\n          promoCode\n        }\n      }\n    }\n  }\n": types.HbAddPromoCodeDocument,
    "\n  mutation StartTransaction($input: BookingFlowCartTransactionModelInput) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaTransaction(input: $input) {\n          paymentId\n          transactionId\n          _error {\n            errorCode\n            errorMessage\n          }\n        }\n      }\n    }\n  }\n": types.StartTransactionDocument,
    "\n  fragment BLSpaTransportationOffer on BookingFlowCartGetProductAvailabilityResponse {\n    offer {\n      offerId\n      productId\n      time\n      available\n      price\n      componentType\n      componentId\n      subOffers {\n        additionalInfo\n      }\n    }\n  }\n": types.BlSpaTransportationOfferFragmentDoc,
    "\n  query BLSpaTransportationAvailability(\n    $input: BookingFlowGetProductAvailabilityRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaProductAvailability(input: $input) {\n          ...BLSpaTransportationOffer\n        }\n      }\n    }\n  }\n": types.BlSpaTransportationAvailabilityDocument,
    "\n  query BLTransferLocations {\n    Bluelagoon {\n      Booking {\n        cartDayspaTransferLocations {\n          locations {\n            locationId\n            componentId\n            type\n            name\n            address\n            city\n            latitude\n            longitude\n          }\n        }\n      }\n    }\n  }\n": types.BlTransferLocationsDocument,
    "\n  mutation InitMembershipTransaction(\n    $input: BookingFlowOrderMembershipTransactionModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postMembershipTransactionInit(input: $input) {\n          transactionId\n          membershipId\n          paymentTransactionId\n        }\n      }\n    }\n  }\n": types.InitMembershipTransactionDocument,
    "\n  mutation FinalizeMembershipTransaction(\n    $input: BookingFlowOrderMembershipFinalizeTransactionModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postMembershipTransactionFinalize(input: $input) {\n          authorizationCode\n          transactionId\n          cardHolder {\n            id\n            firstName\n            lastName\n            personalId\n            email\n            phoneNumber\n          }\n        }\n      }\n    }\n  }\n": types.FinalizeMembershipTransactionDocument,
    "\n  query InRoomProducts{\n    Bluelagoon {\n      Booking {\n        cartHotelProducts {\n          products {\n            productId\n            price\n            displayName\n          }\n        }\n      }\n    }\n  }\n": types.InRoomProductsDocument,
    "\n  fragment ConversionRates on PaymentPlanetCurrencyConversionRate {\n    currency\n    value\n  }\n": types.ConversionRatesFragmentDoc,
    "\n  fragment ErrorFragment on MicrosoftAspNetCoreMvcProblemDetails {\n    title\n    status\n    detail\n    type\n    instance\n  }\n": types.ErrorFragmentFragmentDoc,
    "\n  query getCurrencyRates {\n    Bluelagoon {\n      Booking {\n        paymentCurrencyRates {\n          ... on PaymentPlanetCollectCurrencyConversionRatesResponse {\n            conversionRates {\n              rates {\n                ...ConversionRates\n              }\n            }\n          }\n          ...ErrorFragment\n        }\n        paymentMulticurrencyRates {\n          ... on PaymentPlanetCollectCurrencyConversionRatesResponse {\n            conversionRates {\n              reportDetail {\n                retrievalReferenceNumber\n              }\n              rates {\n                ...ConversionRates\n              }\n            }\n          }\n          ...ErrorFragment\n        }\n      }\n    }\n  }\n": types.GetCurrencyRatesDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment GiftCardFragment on GiftCardGetGiftCardResponse {\n    number\n    status\n    validTo\n    externalDocumentNo\n    amount\n  }\n"): (typeof documents)["\n  fragment GiftCardFragment on GiftCardGetGiftCardResponse {\n    number\n    status\n    validTo\n    externalDocumentNo\n    amount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation findGiftcardQuery(\n    $input: BookingFlowFindGiftCardRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFind(input: $input) {\n          ...GiftCardFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation findGiftcardQuery(\n    $input: BookingFlowFindGiftCardRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFind(input: $input) {\n          ...GiftCardFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation FindGiftCardExternalDocument(\n    $input: BookingFlowFindGiftCardRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFind(input: $input) {\n          externalDocumentNo\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation FindGiftCardExternalDocument(\n    $input: BookingFlowFindGiftCardRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFind(input: $input) {\n          externalDocumentNo\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation BLGetGiftCard($input: BookingFlowFindGiftCardRequestModelInput!) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFind(input: $input) {\n          amount\n          number\n          externalDocumentNo\n          customerName\n          customerEmail\n          message\n          shippingMethod\n          status\n          recipientName\n          recipientAddress\n          recipientPostCode\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation BLGetGiftCard($input: BookingFlowFindGiftCardRequestModelInput!) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFind(input: $input) {\n          amount\n          number\n          externalDocumentNo\n          customerName\n          customerEmail\n          message\n          shippingMethod\n          status\n          recipientName\n          recipientAddress\n          recipientPostCode\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query BLGetTransactionStatus($search: String!) {\n    Bluelagoon {\n      Booking {\n        paymentTransactionStatusBySearch(search: $search) {\n          ... on PaymentPlanetGetPaymentStatusResponse {\n            data {\n              refNo\n              data {\n                detail {\n                  fail {\n                    message\n                  }\n                }\n                status\n                refNo\n                transactionId\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query BLGetTransactionStatus($search: String!) {\n    Bluelagoon {\n      Booking {\n        paymentTransactionStatusBySearch(search: $search) {\n          ... on PaymentPlanetGetPaymentStatusResponse {\n            data {\n              refNo\n              data {\n                detail {\n                  fail {\n                    message\n                  }\n                }\n                status\n                refNo\n                transactionId\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation BLCreateGiftCard($input: BookingFlowGiftCardOrderModelInput!) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardCreate(input: $input) {\n          giftCardNo\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation BLCreateGiftCard($input: BookingFlowGiftCardOrderModelInput!) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardCreate(input: $input) {\n          giftCardNo\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation BLFinalizeGiftCard($info: BookingFlowFinalizeGiftCardModelInput!) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFinalize(input: $info) {\n          giftCard {\n            externalDocNo\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation BLFinalizeGiftCard($info: BookingFlowFinalizeGiftCardModelInput!) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFinalize(input: $info) {\n          giftCard {\n            externalDocNo\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BLSpaTimeSlotAvailability on BookingFlowCartGetProductAvailabilityResponse {\n    offer {\n      offerId\n      productId\n      time\n      available\n      price\n    }\n  }\n"): (typeof documents)["\n  fragment BLSpaTimeSlotAvailability on BookingFlowCartGetProductAvailabilityResponse {\n    offer {\n      offerId\n      productId\n      time\n      available\n      price\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation BLCreateNewSpaCart {\n    Bluelagoon {\n      Booking {\n        postCartDayspaNew {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation BLCreateNewSpaCart {\n    Bluelagoon {\n      Booking {\n        postCartDayspaNew {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation BLAddToCart($input: BookingFlowCartAddItemRequestModelInput!) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaAdd(input: $input) {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation BLAddToCart($input: BookingFlowCartAddItemRequestModelInput!) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaAdd(input: $input) {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation BLRemoveItemFromCart($cartId: UUID!, $itemId: String!) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaRemove(input: { cartId: $cartId, itemId: $itemId }) {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation BLRemoveItemFromCart($cartId: UUID!, $itemId: String!) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaRemove(input: { cartId: $cartId, itemId: $itemId }) {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BLSpaExtraAvailability on BookingFlowCartGetProductAvailabilityResponse {\n    offer {\n      offerId\n      time\n      available\n      productId\n      price\n    }\n  }\n"): (typeof documents)["\n  fragment BLSpaExtraAvailability on BookingFlowCartGetProductAvailabilityResponse {\n    offer {\n      offerId\n      time\n      available\n      productId\n      price\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query BLProductAvailability(\n    $input: BookingFlowGetProductAvailabilityRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaProductAvailability(input: $input) {\n          __typename\n          ...BLSpaCalendarAvailability\n          ...BLSpaTimeSlotAvailability\n          ...BLSpaExtraAvailability\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query BLProductAvailability(\n    $input: BookingFlowGetProductAvailabilityRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaProductAvailability(input: $input) {\n          __typename\n          ...BLSpaCalendarAvailability\n          ...BLSpaTimeSlotAvailability\n          ...BLSpaExtraAvailability\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query BLSpaProductCatalog {\n    Bluelagoon { \n      Booking {\n        cartDayspaProducts {\n          products { \n            productId\n            price\n            discountedPrice\n            fixedDiscountPercentage\n            timeSlotLength\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query BLSpaProductCatalog {\n    Bluelagoon { \n      Booking {\n        cartDayspaProducts {\n          products { \n            productId\n            price\n            discountedPrice\n            fixedDiscountPercentage\n            timeSlotLength\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query BLGetSpaCart($cartId: UUID!) {\n    Bluelagoon {\n      Booking {\n        cartDayspaById(id: $cartId) {\n          id\n          paymentAmount\n          paymentRequired\n          promoCode\n          giftCardAmount\n          discountedAmount\n          giftCards {\n            no\n            balance\n            chargeAmount\n          }\n          customer {\n            firstName\n            lastName\n            email\n            phone\n            nationality\n            newsletter\n          }\n          membership {\n            type\n          }\n          items {\n            id\n            productId\n            qty\n            fullName\n            price\n            totalLinePrice # price * qty\n            linePrice # discountedPrice * qty\n            discountedPrice\n            type\n            meta\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query BLGetSpaCart($cartId: UUID!) {\n    Bluelagoon {\n      Booking {\n        cartDayspaById(id: $cartId) {\n          id\n          paymentAmount\n          paymentRequired\n          promoCode\n          giftCardAmount\n          discountedAmount\n          giftCards {\n            no\n            balance\n            chargeAmount\n          }\n          customer {\n            firstName\n            lastName\n            email\n            phone\n            nationality\n            newsletter\n          }\n          membership {\n            type\n          }\n          items {\n            id\n            productId\n            qty\n            fullName\n            price\n            totalLinePrice # price * qty\n            linePrice # discountedPrice * qty\n            discountedPrice\n            type\n            meta\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation BLLoginWithBookingRef($email: String!, $bookingRef: String!) {\n    Bluelagoon {\n      Booking {\n        postMybookingLogin(input: { email: $email, bookingRef: $bookingRef }) {\n          token\n          refreshToken\n          _error {\n            errorCode\n            errorMessage\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation BLLoginWithBookingRef($email: String!, $bookingRef: String!) {\n    Bluelagoon {\n      Booking {\n        postMybookingLogin(input: { email: $email, bookingRef: $bookingRef }) {\n          token\n          refreshToken\n          _error {\n            errorCode\n            errorMessage\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query BLSpaBooking {\n    Bluelagoon {\n      Booking {\n        mybookingBooking {\n          bookingId\n          arrivalTime\n          adultCount\n          teenagerCount\n          childrenCount\n          packageType\n          products {\n            productId\n            qty\n          }\n          customer {\n            email\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query BLSpaBooking {\n    Bluelagoon {\n      Booking {\n        mybookingBooking {\n          bookingId\n          arrivalTime\n          adultCount\n          teenagerCount\n          childrenCount\n          packageType\n          products {\n            productId\n            qty\n          }\n          customer {\n            email\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query BLSpaBookingActivities {\n    Bluelagoon {\n      Booking {\n        mybookingActivities {\n          activityLines {\n            numberOfPersons\n            numberOfAdults\n            numberOfChildren\n            quantity\n            productNo\n            reservationDate\n            comment\n            data\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query BLSpaBookingActivities {\n    Bluelagoon {\n      Booking {\n        mybookingActivities {\n          activityLines {\n            numberOfPersons\n            numberOfAdults\n            numberOfChildren\n            quantity\n            productNo\n            reservationDate\n            comment\n            data\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CheckoutTransaction($input: BookingFlowCartConfirmModelInput!) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaCheckout(input: $input) {\n          bookingNumber\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CheckoutTransaction($input: BookingFlowCartConfirmModelInput!) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaCheckout(input: $input) {\n          bookingNumber\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BLSpaCalendarAvailability on BookingFlowCartGetProductAvailabilityResponse {\n    offer {\n      productId\n      time\n      available\n    }\n  }\n"): (typeof documents)["\n  fragment BLSpaCalendarAvailability on BookingFlowCartGetProductAvailabilityResponse {\n    offer {\n      productId\n      time\n      available\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation BLCustomerInfo($input: BookingFlowCartStoreCustomerModelInput!) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaCustomer(input: $input) {\n          __typename\n          cart {\n            id\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation BLCustomerInfo($input: BookingFlowCartStoreCustomerModelInput!) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaCustomer(input: $input) {\n          __typename\n          cart {\n            id\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation BLAddGiftCard($input: BookingFlowCartAddGiftCardRequestModelInput!) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaAddGiftCard(input: $input) {\n          id\n          giftCards {\n            no\n            balance\n            chargeAmount\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation BLAddGiftCard($input: BookingFlowCartAddGiftCardRequestModelInput!) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaAddGiftCard(input: $input) {\n          id\n          giftCards {\n            no\n            balance\n            chargeAmount\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation BLRemoveGiftCard(\n    $input: BookingFlowCartRemoveGiftCardRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaRemoveGiftCard(input: $input) {\n          id\n          giftCards {\n            no\n            balance\n            chargeAmount\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation BLRemoveGiftCard(\n    $input: BookingFlowCartRemoveGiftCardRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaRemoveGiftCard(input: $input) {\n          id\n          giftCards {\n            no\n            balance\n            chargeAmount\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation HBAddPromoCode(\n    $input: BookingFlowCartAddPromoCodeRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaAddPromoCode(input: $input) {\n          id\n          promoCode\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation HBAddPromoCode(\n    $input: BookingFlowCartAddPromoCodeRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaAddPromoCode(input: $input) {\n          id\n          promoCode\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation StartTransaction($input: BookingFlowCartTransactionModelInput) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaTransaction(input: $input) {\n          paymentId\n          transactionId\n          _error {\n            errorCode\n            errorMessage\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation StartTransaction($input: BookingFlowCartTransactionModelInput) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaTransaction(input: $input) {\n          paymentId\n          transactionId\n          _error {\n            errorCode\n            errorMessage\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BLSpaTransportationOffer on BookingFlowCartGetProductAvailabilityResponse {\n    offer {\n      offerId\n      productId\n      time\n      available\n      price\n      componentType\n      componentId\n      subOffers {\n        additionalInfo\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment BLSpaTransportationOffer on BookingFlowCartGetProductAvailabilityResponse {\n    offer {\n      offerId\n      productId\n      time\n      available\n      price\n      componentType\n      componentId\n      subOffers {\n        additionalInfo\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query BLSpaTransportationAvailability(\n    $input: BookingFlowGetProductAvailabilityRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaProductAvailability(input: $input) {\n          ...BLSpaTransportationOffer\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query BLSpaTransportationAvailability(\n    $input: BookingFlowGetProductAvailabilityRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postCartDayspaProductAvailability(input: $input) {\n          ...BLSpaTransportationOffer\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query BLTransferLocations {\n    Bluelagoon {\n      Booking {\n        cartDayspaTransferLocations {\n          locations {\n            locationId\n            componentId\n            type\n            name\n            address\n            city\n            latitude\n            longitude\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query BLTransferLocations {\n    Bluelagoon {\n      Booking {\n        cartDayspaTransferLocations {\n          locations {\n            locationId\n            componentId\n            type\n            name\n            address\n            city\n            latitude\n            longitude\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InitMembershipTransaction(\n    $input: BookingFlowOrderMembershipTransactionModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postMembershipTransactionInit(input: $input) {\n          transactionId\n          membershipId\n          paymentTransactionId\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation InitMembershipTransaction(\n    $input: BookingFlowOrderMembershipTransactionModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postMembershipTransactionInit(input: $input) {\n          transactionId\n          membershipId\n          paymentTransactionId\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation FinalizeMembershipTransaction(\n    $input: BookingFlowOrderMembershipFinalizeTransactionModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postMembershipTransactionFinalize(input: $input) {\n          authorizationCode\n          transactionId\n          cardHolder {\n            id\n            firstName\n            lastName\n            personalId\n            email\n            phoneNumber\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation FinalizeMembershipTransaction(\n    $input: BookingFlowOrderMembershipFinalizeTransactionModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postMembershipTransactionFinalize(input: $input) {\n          authorizationCode\n          transactionId\n          cardHolder {\n            id\n            firstName\n            lastName\n            personalId\n            email\n            phoneNumber\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query InRoomProducts{\n    Bluelagoon {\n      Booking {\n        cartHotelProducts {\n          products {\n            productId\n            price\n            displayName\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query InRoomProducts{\n    Bluelagoon {\n      Booking {\n        cartHotelProducts {\n          products {\n            productId\n            price\n            displayName\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ConversionRates on PaymentPlanetCurrencyConversionRate {\n    currency\n    value\n  }\n"): (typeof documents)["\n  fragment ConversionRates on PaymentPlanetCurrencyConversionRate {\n    currency\n    value\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ErrorFragment on MicrosoftAspNetCoreMvcProblemDetails {\n    title\n    status\n    detail\n    type\n    instance\n  }\n"): (typeof documents)["\n  fragment ErrorFragment on MicrosoftAspNetCoreMvcProblemDetails {\n    title\n    status\n    detail\n    type\n    instance\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getCurrencyRates {\n    Bluelagoon {\n      Booking {\n        paymentCurrencyRates {\n          ... on PaymentPlanetCollectCurrencyConversionRatesResponse {\n            conversionRates {\n              rates {\n                ...ConversionRates\n              }\n            }\n          }\n          ...ErrorFragment\n        }\n        paymentMulticurrencyRates {\n          ... on PaymentPlanetCollectCurrencyConversionRatesResponse {\n            conversionRates {\n              reportDetail {\n                retrievalReferenceNumber\n              }\n              rates {\n                ...ConversionRates\n              }\n            }\n          }\n          ...ErrorFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getCurrencyRates {\n    Bluelagoon {\n      Booking {\n        paymentCurrencyRates {\n          ... on PaymentPlanetCollectCurrencyConversionRatesResponse {\n            conversionRates {\n              rates {\n                ...ConversionRates\n              }\n            }\n          }\n          ...ErrorFragment\n        }\n        paymentMulticurrencyRates {\n          ... on PaymentPlanetCollectCurrencyConversionRatesResponse {\n            conversionRates {\n              reportDetail {\n                retrievalReferenceNumber\n              }\n              rates {\n                ...ConversionRates\n              }\n            }\n          }\n          ...ErrorFragment\n        }\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;